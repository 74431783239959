import { onMounted, ref, watch } from 'vue';
import { useSessionStorage } from '@vueuse/core';
import * as util from './util';
/** Composable controlling which columns are shown/hidden */
export function useColumnHiding(props) {
    var _a;
    const storageKey = ((_a = props.savePreferences) === null || _a === void 0 ? void 0 : _a.enabled) && props.savePreferences.key
        ? `${props.savePreferences.key}-hidden-column-keys`
        : null;
    const initializationKey = storageKey ? `${storageKey}-initialized` : null;
    /** Collection of all hidden columns */
    /** Use session storage for hiddenColumnKeys */
    const hiddenColumnKeys = storageKey ? useSessionStorage(storageKey, []) : ref([]);
    /** Tracks whether the user explicitly initialized column visibility preferences*/
    const isUserInitialized = initializationKey
        ? useSessionStorage(initializationKey, false)
        : ref(false);
    /** Check if the given column is marked as hidden */
    const isHidden = (column) => props.enableColumnVisibilityToggle && hiddenColumnKeys.value.includes(util.keyOf(column));
    watch(() => props.columns, (newColumns, oldColumns) => {
        (newColumns || []).forEach(column => {
            //check if column is new
            const oldColumn = oldColumns === null || oldColumns === void 0 ? void 0 : oldColumns.find(x => util.keyOf(x) == util.keyOf(column));
            if (oldColumn)
                return;
        });
        // Remove keys that are no longer in the current column set
        hiddenColumnKeys.value = hiddenColumnKeys.value.filter(key => (newColumns !== null && newColumns !== void 0 ? newColumns : []).some(column => util.keyOf(column) === key));
    }, { immediate: true });
    onMounted(() => {
        var _a;
        if (!isUserInitialized.value && hiddenColumnKeys.value.length === 0) {
            // Initialize hiddenColumnKeys with default hidden columns only if user didn't explicitly initialized column visibility preferences
            hiddenColumnKeys.value =
                ((_a = props.columns) === null || _a === void 0 ? void 0 : _a.filter(column => column.hideByDefault).map(column => util.keyOf(column))) || [];
        }
    });
    watch(hiddenColumnKeys, (newKeys, oldKeys) => {
        if (!isUserInitialized.value && newKeys.length !== oldKeys.length) {
            isUserInitialized.value = true;
        }
    });
    return {
        hiddenColumnKeys,
        isHidden,
    };
}
